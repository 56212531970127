<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class=" ma-auto width-400 radius-20 pa-40-30 bg-white position-relative" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="flex-row justify-center">
					<div class="popup-close">
                        <img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
                    </div>
				</div>
				<div
					class="bg-white"
				>
				
					<div
						class="bg-logo text-center"
					>

						<h4 class="mb-20">MAFIA NFT STAKING</h4>
						<div class="top-line pt-30">
							<div
								v-if="item.stakg_fg != 'Y'"
							>
								<strong class="size-px-16">스테이킹을 하시겠습니까?</strong>

								<p class="mt-10">등급에 따라 채굴되는 양이 다릅니다.</p>
							</div>
							<div
								v-else
							>
								스테이킹을 해제 하시겠습니까?
							</div>
						</div>
					</div>

					<div class="mt-30 text-center">
						<button class="btn-inline btn-dark-gray radius-20 mr-10 pa-10-30 font-weight-500" @click="$emit('cancel')">취소</button>
						<button class="btn-inline btn-primary radius-20 pa-10-30 font-weight-500" @click="$emit('click')">확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia033-47'
		, props: ['item']
		, components: { PopupLayer}
		, data: function(){
			return {

			}
		}
	}
</script>