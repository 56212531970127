<template>
	<div>
		<h3 class="focus size-px-16 color-333">입출금 이력</h3>
		<table
			v-if="items.length > 0"
			class="table mt-10"
		>
			<colgroup>
				<col width="150px" />
				<col width="150px" />
				<col width="auto" />
			</colgroup>
			<thead>
				<tr>
					<th class="t-left">입출금 일시</th>
					<th class="t-left">거래 ID</th>
					<th class="t-right">상태</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in items"
					:key="'item_' + index"
				>
					<td>{{ item.deptwidal_daytime }}</td>
					<td>
						<p>{{ item.to_nickname }}</p>
					</td>
					<td class="tb_ing_gray">{{  item.state_name }}</td>
				</tr>
			</tbody>
		</table>

		<div
			v-else
			class="none mt-30"
		>
			입출금 이력이 없습니다.
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia022'
	, props: ['is_dual', 'user', 'card']
	, data: function(){
		return {
			program: {
				name: '입출금 이력'
			}
			, nft_id: this.$route.params.id
			, items: []
			, item_search: {
				page: this.$language.base.page_number
				, list_cnt: this.$language.base.pagerecnum
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_history
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
				})
				if(result.success){
					this.items = result.data.nft_card_transaction_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.getData()
	}
}
</script>