<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="bg-white ma-auto width-500 radius-20" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="pa-16-30 flex-row justify-space-between position-relative" style="background-color: var(--mafia-Blue);">
					<h4 class="color-white size-px-20">MAFIA NFT 선물</h4>
					<div class="popup-close">
						<img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close.svg')" />
					</div>
					
				</div>

				<div
					class="pa-13-30 flex-row under-line"
				>
					<input
						v-model="item_search.search_value"
						type="text"
						placeholder="친구 검색"
						class="flex-1"
					/>
					<v-icon
						class="cursor-pointer"
						@click="getData"
					>mdi mdi-magnify</v-icon>
				</div>
				<div
					class="under-line pa-16-30"
					style="padding-top: 30px;"
				>카드명 <span class="color-secondary font-weight-bold">{{ nft_card.cartl_nft_kind_name ? nft_card.cartl_nft_kind_name : nft_card.nft_card_name }}</span></div>
				<div class="">
					<template
						v-if="items.length > 0"
					>
						
						<div class="pa-16-30">
							<ul
								v-if="search_list.length > 0"
								class="overflow-y-auto flex-column gap-15 scroll-gray pr-15"
								style="max-height: 394px;"
							>
								<li
									v-for="(account, index) in search_list"
									:key="'item_' + index"
									class="flex-row justify-space-between items-center"
								>
									<div class="icon icon-64 mr-10">
										<img :src="$request.upload_url(account.profle_nft_card_img_url)" @error="$bus.$emit('onErrorProfileImage', $event)" class="width-100 " style="height: 100%;"/>
									</div>

									<div class="flex-1 flex-column justify-center">
										<div class="size-px-16 color-333 font-weight-500">{{ account.nickname }}</div>
										<div class="size-px-14 color-888 mt-5"
										style="overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;
										width: 280px;">{{ account.state_message}}</div>
									</div>
									<div><button
										class="btn btn-primary-outline radius-15 size-px-12 font-weight-500"
										style="padding:9px 20px; line-height: 100%;"
										@click="$emit('click', account)"
									>선물</button></div>
								</li>
							</ul>
							<div
								v-else
								class="none"
							>검색된 친구가 없습니다</div>
						</div>
					</template>

					<div
						v-else
						class="none mt-30"
					>선물 가능한 친구가 없습니다</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia025'
	,
	components: {PopupLayer},
	props: ['nft_card', 'user']
	, data: function(){
		return {
			program: {
				name: 'NFT 선물'
			}
			, type: this.$route.params.type ? this.$route.params.type: 'follower'
			, items: []
			, search_list: []
			, item: {
				friend_member_number: ''
			}
			, item_search: {
				page: 1
				, list_cnt: 10
				, search_value: ''
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_friends_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
						, srchtext: this.item_search.search_value
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.friend_list
					this.search_list = result.data.friend_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,doSearch: function(call){
			// let call = this.search.nick
			
			this.search_list = []

			this.items.forEach((item) => {

				if(call == '' || item.nickname.indexOf(call) > -1){
					this.search_list.push(item)
				}
			})

			this.$log.console(this.search_list)
		}
		,click: function(){
			this.$log.console('this.item', this.item)
			if(!this.item.friend_member_number){
				this.$bus.$emit('notify', { type: 'error', message: '선택된 친구가 없습니다.'})
				return false
			}else{
				this.$emit('click', this.item)
			}
		}
	}
	,created() {
		this.getData()
	}
}
</script>

<style>
input::placeholder{
	color:#bbb;
}
</style>